///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import { CustomCard, Row, Col, MiniNav, CustomButton, Fade } from '@ibiliaze/reactstrap';
import { HeaderDisplayer, PageSection, scroll, Section } from '@ibiliaze/react-base';
import { FadeIn, TransformYDown } from '@ibiliaze/react-intersect';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postItem, deleteItem } from '../../actions/cart';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Cart from '../Cart/Cart';
//////////////////////////////////////////////////////////////////////////////////////////

const Services = ({ cart, postItem, deleteItem }) => {
  // State
  const [cartModal, setCartModal] = useState(false);

  // Toggle functions
  const toggleCartModal = _ => setCartModal(!cartModal);

  // onClick functions
  const onAddClick = async service => {
    try {
      await postItem({
        serviceName: service.serviceName,
        price: service.price,
        id: service.id,
        quantity: 1,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onRemoveClick = async service => {
    try {
      await deleteItem(service.id);
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <div className='p-t-xl p-b-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.services} />
          </h1>
        </FadeIn>

        <MiniNav
          navlinks={c.services.serviceGroups.map((serviceGroup, i) => ({
            title: serviceGroup.serviceGroupName,
            href: '#',
            onClick: _ => {
              scroll(document.getElementById(`services-${serviceGroup.serviceGroupName}-section`)?.offsetTop);
            },
          }))}
        />

        <PageSection id='services-section' className='p-t-l'>
          {c.services.serviceGroups.map((serviceGroup, i) => (
            <Section key={i} className='p-t-xxl' id={`services-${serviceGroup.serviceGroupName}-section`}>
              <br />
              <FadeIn>
                <h4 className='t-align-c'>{serviceGroup.serviceGroupName}</h4>
              </FadeIn>
              <br />
              <Row>
                {serviceGroup.serviceItems.map((service, i) => (
                  <Col key={i} md={3}>
                    <TransformYDown>
                      <CustomCard
                        // Title
                        title={service.serviceName}
                        titleTag='div'
                        hoverTitle='Add to Cart'
                        clickedTitle={'Added - ' + service.serviceName}
                        // Subtitle
                        subtitle={c.services.showPrice && c.currency.currencySign + service.price}
                        subtitleTag='div'
                        // Text
                        text={service.text}
                        // Misc
                        style={c.services.style}
                        className={c.services.className}
                        imgSrc={c.services.showImage && service.imageSrc}
                        multiclick={c.services.multiclick}
                        onCardClickHandler={onAddClick}
                        onCardSecondClickHandler={onRemoveClick}
                        args={service}
                      />
                    </TransformYDown>
                  </Col>
                ))}
              </Row>
            </Section>
          ))}
        </PageSection>

        <Cart modal={cartModal} toggle={toggleCartModal} />
      </div>

      {cart?.items?.length !== 0 && (
        <Fade className='bottom-bar'>
          <div>{cart?.items?.length || 0} items</div>
          <CustomButton color='primary' size='sm' onClick={toggleCartModal}>
            Continue
          </CustomButton>
        </Fade>
      )}
    </>
  );
};

const mapStateToProps = state => ({ cart: state.cart });

export default connect(mapStateToProps, { postItem, deleteItem })(Services);
