///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  CustomButton,
  CustomInput,
  Form,
  FormGroup,
  Label,
  MainImage,
  Row,
  TextButton,
} from '@ibiliaze/reactstrap';
import { Flipper } from '@ibiliaze/react-multirow';
import { FullPage, PageSection } from '@ibiliaze/react-base';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { pushEmail } from '../../actions/email';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ContactPage = ({ pushEmail }) => {
  // State
  const [inputs, setInputs] = useState({ name: '', tel: '', email: '', subject: '', query: '' });

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onSubmit function
  const onSubmit = async e => {
    try {
      e.preventDefault();
      await pushEmail({
        // Mail
        subject: 'New Query',
        to: c.email.email1,
        successReply: 'Query submitted to support',
        // HTML
        template: 'adminQuery',
        args: { ...inputs },
      });

      await pushEmail({
        // Mail
        subject: 'Query submitted',
        to: inputs.email,
        successReply: 'Your query was submitted successfully',
        // HTML
        template: 'query',
        args: { ...inputs },
      });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <div className='p-b-l'>
        <MainImage {...c.mainPics.contactPage} title={c.infos.contact.header} />
      </div>

      <PageSection className='p-t-l p-b-l'>
        <Row>
          <Col md={4}>
            <Flipper img={c.infos.contact.flipperImg} title={c.infos.contact.flipperHeader}>
              <div className=' p-s'>
                <h5>{c.infos.contact.flipperHeader2}</h5>
                <br />

                {Object.keys(c.tel).map((tel, i) => (
                  <div key={i}>
                    <TextButton onClick={_ => (window.location.href = `tel:${c.tel[tel]}`)}>{c.tel[tel]}</TextButton>
                    <br />
                  </div>
                ))}
                {Object.keys(c.email).map((email, i) => (
                  <div key={i}>
                    <TextButton onClick={_ => (window.location.href = `mailto:${c.email[email]}`)}>
                      {c.email[email]}
                    </TextButton>
                    <br />
                  </div>
                ))}
              </div>
            </Flipper>
          </Col>
          <Col md={8}>
            <h2>{c.infos.contact.header2}</h2>

            <br />

            <Form onSubmit={onSubmit}>
              <FormGroup floating>
                <CustomInput required name='name' value={inputs.name} onChange={onInputsChange} placeholder='Name *' />
                <Label>Name *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  name='email'
                  value={inputs.email}
                  onChange={onInputsChange}
                  placeholder='Email *'
                />
                <Label>Email *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  type='number'
                  name='tel'
                  value={inputs.tel}
                  onChange={onInputsChange}
                  placeholder='Phone *'
                />
                <Label>Phone *</Label>
              </FormGroup>

              <FormGroup floating>
                <CustomInput
                  required
                  name='subject'
                  value={inputs.subject}
                  onChange={onInputsChange}
                  placeholder='Subject *'
                />
                <Label>Subject *</Label>
              </FormGroup>

              <FormGroup>
                <Label>Query *</Label>
                <CustomInput
                  required
                  type='textarea'
                  rows='5'
                  name='query'
                  value={inputs.query}
                  onChange={onInputsChange}
                  placeholder='What is your query info?'
                />
              </FormGroup>

              <CustomButton color='primary' type='submit'>
                Submit Query
              </CustomButton>
            </Form>
          </Col>
        </Row>
      </PageSection>
    </FullPage>
  );
};

export default connect(null, { pushEmail })(ContactPage);
