///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainImage } from '@ibiliaze/reactstrap';
import { FadeIn } from '@ibiliaze/react-intersect';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import About from '../Dashboard/About';
import Copy from '../Dashboard/Copy';
import Album from '../Dashboard/Album';
import Services from '../Dashboard/Services';
import Reviews from '../Dashboard/Reviews';
//////////////////////////////////////////////////////////////////////////////////////////

const HomePage = () => {
  // State
  const [input, setInput] = useState('');

  // History
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <FadeIn>
        <MainImage
          {...c.mainPics.homePage}
          {...c.socials}
          title={c.name}
          slogan={c.slogan}
          mobileWidth={c.mobileWidth}
          inputValue={input}
          onInputChange={e => setInput(e.target.value)}
          onButtonClick={_ => c.mainPics.homePage.onButtonClick(navigate, input)}
        />
      </FadeIn>
      <About />
      <Copy />
      <Album />
      <Reviews />
      <Services />
    </>
  );
};

export default HomePage;
