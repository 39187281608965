///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { CustomNav } from '@ibiliaze/reactstrap';
import { scroll } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Cart from '../Cart/Cart';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = () => {
  // State
  const [cartModal, setCartModal] = useState(false);

  // Toggle functions
  const toggleCartModal = _ => setCartModal(!cartModal);

  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <CustomNav
        {...c.nav}
        navbarBrand={c.name}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        navlinks={[
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('about-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => {
              navigate('/');
              setTimeout(() => {
                scroll(document.getElementById('services-section')?.offsetTop);
              }, 1);
            },
            hide: false,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              navigate('/contact');
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: false,
          },
        ]}
        navlinksRight={[]}
      />

      <Cart modal={cartModal} toggle={toggleCartModal} />
    </>
  );
};

const mapStateToProps = state => ({ cart: state.cart });

export default connect(mapStateToProps)(Header);
