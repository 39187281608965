///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { Col, Row, CustomCard, MainImage } from '@ibiliaze/reactstrap';
import { HeaderDisplayer, PageSection } from '@ibiliaze/react-base';
import { FadeIn, ScaleIn } from '@ibiliaze/react-intersect';
import { TextType, CircleProgress } from '@ibiliaze/react-text-type';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => {
  // History
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <div className='p-b-xl'>
        <FadeIn>
          <MainImage {...c.mainPics.copy1} mobileWidth={c.mobileWidth} parallaxPace={0.3} />
        </FadeIn>
      </div>
      <PageSection className='p-t-xl p-b-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.infos5} />
          </h1>
        </FadeIn>

        <Row className='p-l-xxl p-r-xxl'>
          {c.infos.infos5.map((a, i) => (
            <Col key={i} md={4} className='p-l-m p-r-m'>
              <FadeIn>
                <CircleProgress {...a} />
                <br />
                <h5 className='t-align-c'>{a.title}</h5>
                <hr />
              </FadeIn>
            </Col>
          ))}
        </Row>

        <div className='m-t-l' />

        <h4 className='t-align-c'>
          <TextType strings={c.textType} />
        </h4>

        <Row>
          {c.infos.infos6.map((a, i) => (
            <Col key={i} md={4}>
              <ScaleIn>
                <CustomCard
                  imgSrc={a.img}
                  a
                  multiclick={false}
                  title={a.header}
                  titleTag='h6'
                  subtitleTag='h6'
                  text={a.body}
                  clickedTitle={'Added - '}
                  onCardClickHandler={_ => a.onClick(navigate)}
                  onCardSecondClickHandler={_ => {}}
                  // args={p}
                />
              </ScaleIn>
            </Col>
          ))}
        </Row>
      </PageSection>
    </>
  );
};

export default Copy;
