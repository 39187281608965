////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { CustomCarousel } from '@ibiliaze/reactstrap';
import { FadeIn, TransformYDown } from '@ibiliaze/react-intersect';
import { HeaderDisplayer, Section } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = () => (
  <Section id='reviews-section' className='custom-page p-t-xl p-b-xl'>
    <FadeIn>
      <h1 className='t-align-c'>
        <HeaderDisplayer header={c.headers.reviews} />
      </h1>
    </FadeIn>
    <TransformYDown>
      <CustomCarousel items={c.reviews} fade={false} indicators={false} />
    </TransformYDown>
  </Section>
);

export default Reviews;
