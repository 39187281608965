////////////////////////////////////////////////////////////////////////////////ITEM TYPES
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILURE = 'GET_ITEMS_FAILURE';
export const POST_ITEM_SUCCESS = 'POST_ITEM_SUCCESS';
export const POST_ITEM_FAILURE = 'POST_ITEM_FAILURE';
export const PUT_ITEM_SUCCESS = 'PUT_ITEM_SUCCESS';
export const PUT_ITEM_FAILURE = 'PUT_ITEM_FAILURE';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const RESET_BASKET_SUCCESS = 'RESET_BASKET_SUCCESS';
export const RESET_BASKET_FAILURE = 'RESET_BASKET_FAILURE';
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const PUSH_EMAIL_SUCCESS = 'PUSH_EMAIL_SUCCESS';
export const PUSH_EMAIL_FAILURE = 'PUSH_EMAIL_FAILURE';
///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
//////////////////////////////////////////////////////////////////////////////////////////
