///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//////////////////////////////////////////////////////////////////////////////////////////

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0); // Delay scroll action
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTopOnRouteChange;
