///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { Col, CustomButton, CustomToast, CustomToastBody, CustomToastHeader, Row } from '@ibiliaze/reactstrap';
import { Flipper, Multirow, IconInfo } from '@ibiliaze/react-multirow';
import { Section, HeaderDisplayer, Page } from '@ibiliaze/react-base';
import { FadeIn, ScaleIn, TransformYDown } from '@ibiliaze/react-intersect';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => {
  // History
  const navigate = useNavigate();

  // JSX
  return (
    <Page>
      <Section id='about-section' className='p-t-xl p-b-xl'>
        <br />

        <Row className='p-b-m'>
          {c.infos.infos1.map((a, i) => (
            <Col key={i} md={4}>
              <TransformYDown>
                <Multirow
                  img={a.img}
                  alt={a.alt}
                  header={a.header}
                  body={a.body}
                  onClick={_ => a.onClick(navigate)}
                  icon={a.icon()}
                />
              </TransformYDown>
            </Col>
          ))}
        </Row>

        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.infos2} />
          </h1>
        </FadeIn>

        <Row>
          {c.infos.infos2.map((a, i) => (
            <Col key={i}>
              <ScaleIn>
                <IconInfo icon={a.icon()} header={a.header} onClick={_ => a.onClick(navigate)} />
              </ScaleIn>
            </Col>
          ))}
        </Row>
      </Section>

      <Section className='p-t-xl p-b-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.infos3} />
          </h1>
        </FadeIn>

        <Row>
          <Col md={8}>
            <FadeIn>
              <h4>{c.infos.infos3.title}</h4>
            </FadeIn>
            <p>{c.infos.infos3.text}</p>
            <CustomButton color='primary' onClick={_ => c.infos.infos3.buttonOnClick(navigate)}>
              {c.infos.infos3.buttonText}
            </CustomButton>
          </Col>
          <Col md={4}>
            <ScaleIn>
              <Flipper img={c.infos.infos3.img1} alt={c.infos.infos3.alt1} title={c.infos.infos3.flipperTitle}>
                <div className='t-align-c p-s'>
                  <h5>{c.infos.infos3.flipperFlippedTitle}</h5>
                  <p>{c.infos.infos3.flipperFlippedText}</p>
                </div>
              </Flipper>
            </ScaleIn>
          </Col>
        </Row>
      </Section>

      <Section className='p-t-xl p-b-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.infos4} />
          </h1>
        </FadeIn>

        <Row>
          <Col md={4}>
            <h3>
              <HeaderDisplayer header={c.infos.infos4.header1} />
            </h3>
            <p>{c.infos.infos4.text1}</p>
            <h4>
              <HeaderDisplayer header={c.infos.infos4.header2} />
            </h4>
            <p>{c.infos.infos4.text2}</p>

            <CustomButton color='primary' onClick={_ => c.infos.infos4.buttonOnClick(navigate)}>
              {c.infos.infos4.buttonText}
            </CustomButton>
            <br />
            <br />
          </Col>
          <Col md={8}>
            <Row>
              {c.infos.infos4.infos.map((info, i) => (
                <Col key={i} md={6}>
                  <CustomToast>
                    <CustomToastHeader>{info.header}</CustomToastHeader>
                    <CustomToastBody>{info.body}</CustomToastBody>
                  </CustomToast>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Section>
    </Page>
  );
};

export default About;
