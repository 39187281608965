///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row } from '@ibiliaze/reactstrap';
import { HeaderDisplayer, PageSection } from '@ibiliaze/react-base';
import { FadeIn } from '@ibiliaze/react-intersect';
import { InstagramEmbed } from 'react-social-media-embed';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import AlbumImage from './AlbumImage';
//////////////////////////////////////////////////////////////////////////////////////////

const Album = () => (
  <PageSection>
    {c.albums.showAlbum && (
      <div className='p-b-xl p-t-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            <HeaderDisplayer header={c.headers.album} />
          </h1>
        </FadeIn>

        <Row className='m-auto t-align-c'>
          {c.albums.folder1.album.map((img, i) => (
            <Col
              key={i}
              md={c.albums.folder1.md || [2, 3, 4][Math.floor(Math.random() * [2, 3, 4].length)]}
              className='m-auto t-align-c'
            >
              <FadeIn>
                <AlbumImage imgSrc={img} />
              </FadeIn>
            </Col>
          ))}
        </Row>
      </div>
    )}

    {c.albums.showInstagram && (
      <div className='p-b-xl p-t-xl'>
        <h1 className='t-align-c'>See Our Full Collection</h1>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <InstagramEmbed url={`https://www.instagram.com/${c.socials.instagram}/`} width='100%' />
        </div>
      </div>
    )}
  </PageSection>
);

export default Album;
