///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomInput,
  CustomButton,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormText,
  Label,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { deleteItem, putItem, resetCart } from '../../actions/cart';
import { pushEmail } from '../../actions/email';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Item from './Item';
//////////////////////////////////////////////////////////////////////////////////////////

const Cart = ({ toggle, modal, cart, deleteItem, putItem, pushEmail }) => {
  // State
  const [inputs, setInputs] = useState({ name: '', email: '', tel: '' });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onSubmit function
  const onSubmit = e => c.cart.cartOnSubmit({ e, inputs, cart, pushEmail });

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>{c.cart.cartName}</ModalHeader>
        <ModalBody>
          <div className='xs-text'>
            <Table borderless>
              <thead>
                <tr>
                  <th>Services</th>
                  {c.cart.showPrice && <th>Price</th>}
                  {c.cart.showQuantity && <th>Quantity</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cart.items.map((item, i) => (
                  <tr key={i}>
                    <Item service={item} putItem={putItem} deleteItem={deleteItem} />
                  </tr>
                ))}
                {c.cart.showTotal && (
                  <tr>
                    <th className='t-f-s-l'>Total</th>
                    <th className='t-f-s-l'>
                      {c.currency.currencySign}
                      {(!!cart?.total && typeof cart?.total === 'number' && cart?.total?.toFixed(2)) || 0}
                    </th>
                  </tr>
                )}
              </tbody>
            </Table>
            {cart.items.length === 0 && <FormText>Add a service to your cart</FormText>}
          </div>

          <FormGroup floating>
            <CustomInput required name='name' placeholder='Name *' value={inputs.name} onChange={onInputsChange} />
            <Label>Name *</Label>
          </FormGroup>
          <FormGroup floating>
            <CustomInput required name='email' placeholder='Email *' value={inputs.email} onChange={onInputsChange} />
            <Label>Email *</Label>
          </FormGroup>
          <FormGroup floating>
            <CustomInput
              required
              name='tel'
              type='number'
              placeholder='Phone *'
              value={inputs.tel}
              onChange={onInputsChange}
            />
            <Label>Phone *</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <CustomButton type='submit' disabled={cart.items.length === 0 ? true : false} color='primary'>
            {c.cart.cartSubmitButton}
          </CustomButton>
          <CustomButton onClick={toggle}>Cancel</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ cart: state.cart });

export default connect(mapStateToProps, { deleteItem, putItem, resetCart, pushEmail })(Cart);
