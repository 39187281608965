/////////////////////////////////////////////////////////////////////////////////////UTILS
import { env } from './constants';
//////////////////////////////////////////////////////////////////////////////////////////

export const resolveHost = () => {
  /**
   * This function will determine if the app is running in PRODUCTION mode
   * If so, the origin URL will be https://<domain>.com.
   * Else, it will be http://192.168.0.131:5000 or http://localhost:5000.
   */
  return !!env.REACT_APP_ENV && !!env.REACT_APP_ENV.includes('production')
    ? env.REACT_APP_API_URL
    : env.REACT_APP_API_DEV_URL;
};

export const resolveEmail = () => {
  return !!process.env.NODE_ENV && !!process.env.NODE_ENV.includes('production')
    ? {
        service: process.env.REACT_APP_MAIL_SERVICE,
        host: process.env.REACT_APP_MAIL_SERVER,
        user: process.env.REACT_APP_MAIL_USER,
        pass: process.env.REACT_APP_MAIL_PW,
      }
    : {
        service: process.env.REACT_APP_MAIL_DEV_SERVICE,
        host: process.env.REACT_APP_MAIL_DEV_SERVER,
        user: process.env.REACT_APP_MAIL_DEV_USER,
        pass: process.env.REACT_APP_MAIL_DEV_PW,
      };
};
