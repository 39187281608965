///////////////////////////////////////////////////////////////////////////////////MODULES
import { Footer as CustomFooter } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import { resolveHost } from '../../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////PACKAGE JSON
import packageJson from '../../../package.json';
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = () => (
  <CustomFooter
    title={c.name}
    textButtons={[]}
    navLinks={[
      {
        title: 'Privacy Policy',
        href: resolveHost() + '/privacy-policy',
      },
      {
        title: 'Terms & Conditions',
        href: resolveHost() + '/terms-conditions',
      },
      {
        title: 'Disclaimer',
        href: resolveHost() + '/disclaimer',
      },
      {
        title: 'Refunds',
        href: resolveHost() + '/return-policy',
      },
    ]}
    miniTexts={[`Version: ${packageJson.version}`]}
  />
);

export default Footer;
