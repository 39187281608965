///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { ImageViewer } from '@ibiliaze/react-dropzone';
//////////////////////////////////////////////////////////////////////////////////////////

const AlbumImage = ({ imgSrc }) => {
  // State
  const [modal, setModal] = useState(false);

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // JSX
  return (
    <>
      <img onClick={toggle} alt={imgSrc} src={imgSrc} className='album-img' />
      <ImageViewer modal={modal} setModal={setModal} imgSrc={imgSrc} />
    </>
  );
};

export default AlbumImage;
