// eslint-disable-next-line no-unused-vars
const env = process.env;

export const config = ({
  // Have to set default values for repo-generator
  scroll = () => {},
  IOS17Filled = () => {},
}) => {
  /**
   * Before changing anything check the repo generator index.js and vanilla.json
   *
   * Icons needs to be denoted as functions. For example:
   * icon: _ => Fluency({ name: 'money', size: '64' })
   * And when used in a component, they need to be called as functions: icon()
   */
  const configObj = {
    // GENERAL INFO
    name: 'Webify Lake',
    domain: 'webifylake.com',
    slogan: 'Build your online presence now',
    mobileWidth: 767,

    // CONTACT
    tel: { tel1: '+447852878811' },
    email: { email1: 'info@webifylake.com', email2: 'ibiorders101@gmail.com' },

    // CHAT
    chat: {
      showWidget: true,
      avatar: '/img/ibi.jpg',
      person: 'Ibi',
      tel: '+447852878811',
      message: `Hello there! \n \nHow can we help?`,
      status: `Typically replies within a few minutes`,
    },

    // SOCIALS
    socials: {
      instagram: 'webifylake',
      facebook: 'webifylake',
      twitter: 'webifylake',
      github: 'ibiliaze',
    },

    // NAV
    nav: {
      dark: true,
      blur: '1px',
      logoSrc: '/img/logo/logo.png',
      logoStyle: { height: '40px' },
      fadeOnPaths: ['/', '/contact'],
    },

    // MAIN PIC
    mainPics: {
      homePage: {
        imgPath: '/img/page/home.jpg',
        logo: '/img/logo/logo.png',
        height: '500px',
        mobileHeight: '300px',
        buttonText: 'Browse',
        onButtonClick: (navigate, input) => scroll(document.getElementById('services-section')?.offsetTop),
        inputPlaceHolder: 'What are you looking for?',
        inputOptions: [
          { label: 'Websites' },
          { label: 'Hosting' },
          { label: 'Email Marketing' },
          { label: 'Facebook Ads' },
          { label: 'X Ghost-writing' },
          { label: 'Instagram Management' },
        ],
        formGroup: true,
      },
      copy1: {
        imgPath: '/img/copy/1.jpg',
        parallax: true,
        parallaxPace: 0.35,
        noShadow: true,
        imgBottom: '0%',
        sloganCenter: true,
        slogan:
          '"That businesses with a strong online presence were more likely to see increased revenue and growth." - Harvard Business School',
      },
      contactPage: {
        imgPath: '/img/page/contact.jpg',
        height: '300px',
        mobileHeight: '300px',
      },
    },

    // HEADERS
    headers: {
      infos2: {
        part1: { text: 'Our Most ' },
        part2: { text: 'Popular Categories', gradient: true },
      },
      infos3: {
        part1: { text: 'Are You Lacking ' },
        part2: { text: 'Online Presence', gradient: true },
        part3: { text: '?' },
      },
      infos4: {
        part1: { text: 'Grow Your Business ', gradient: true },
        part2: { text: 'With Your Customer Experience' },
      },
      infos5: {
        part1: { text: '100% ', gradient: true },
        part2: { text: 'Satisfied Users' },
      },
      album: {
        part1: { text: 'Our Clients' },
      },
      reviews: {
        part1: { text: 'Reviews' },
      },
      services: {
        part1: { text: 'Services' },
      },
    },

    // TYPE EFFECTS
    textType: ['Websites', 'Email Marketing', 'X Ghostwriting', 'FaceBook/Instagram Ads'],

    // INFOS
    infos: {
      infos1: [
        {
          header: 'Increased sales and revenue',
          body: 'Having a strong social media presence can improve your search engine rankings, making it easier for people to find your business online.',
          img: '/img/infos/infos1-1.jpg',
          alt: 'infos1-1',
          icon: _ => IOS17Filled({ name: 'money', size: '50', color: configObj.extras.iconColor1 }),
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Connecting with Target Audience',
          body: 'Connect with your target audience and build relationships with them.',
          img: '/img/infos/infos1-2.jpg',
          alt: 'infos1-2',
          icon: _ => IOS17Filled({ name: 'america', size: '50', color: configObj.extras.iconColor1 }),
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Establish an authority',
          body: 'Establish yourself as an authority in your industry and gain their trust.',
          img: '/img/infos/infos1-3.jpg',
          alt: 'infos1-3',
          icon: _ => IOS17Filled({ name: 'crown', size: '50', color: configObj.extras.iconColor1 }),
          onClick: navigate => window.open('/auth'),
        },
      ],
      infos2: [
        {
          header: 'Websites',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'america', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: 'Ads',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'poster', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: 'Ghostwriting',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'edit-chat-history', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: 'Management',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'multiple-devices', size: '50', color: configObj.extras.iconColor2 }),
        },
        {
          header: 'Marketing',
          onClick: navigate => scroll(document.getElementById('services-section').offsetTop),
          icon: _ => IOS17Filled({ name: 'fund-accounting', size: '50', color: configObj.extras.iconColor2 }),
        },
      ],
      infos3: {
        title: 'What do we do?',
        text: 'We specialize in aiding digital marketers with prospecting by offering tools for visualizing potential clients and automating outreach. Our platform leverages data analytics and machine learning to provide insights into market segments, allowing for targeted strategies. With our automated messaging system, users can effortlessly send personalized messages, streamlining the prospecting process.',
        buttonText: 'Check Our Services',
        img1: '/img/infos/infos2-1.jpg',
        alt1: 'infos2-1',
        buttonOnClick: navigate => scroll(document.getElementById('services-section').offsetTop),
        flipperTitle: 'Webify Lake',
        flipperFlippedTitle: 'Webify Lake',
        flipperFlippedText:
          "You don't have to manage anything! You can choose to increase your server size if you have a large customer base.",
      },
      infos4: {
        header1: {
          part1: { text: 'About Your ' },
          part2: { text: 'Best Service', gradient: true },
        },
        header2: {
          part1: { text: 'How does the process work?' },
        },
        text1:
          "We host your web application in the cloud with maximum security. Your hosting service, domain registration, SSL certificate attachement will be done by us. You don't have to manage anything! You can choose to increase your server size if you have a large customer base.",
        text2:
          'Once you wish to begin hosting your website, one of our experienced engineers will contact you, they will work with you to help to personalise your website. Once complete we will host the website. Simple as that!',
        buttonText: 'Explore Options',
        buttonOnClick: navigate => scroll(document.getElementById('services-section')?.offsetTop),
        infos: [
          {
            header: 'Free Payment Systems',
            body: 'Add a FREE payment system addon to your site.',
          },
          {
            header: 'Admin Management',
            body: 'You can have the ability to create admin users that will manage your customer servicing needs.',
          },
          {
            header: 'Free Emails',
            body: '125,000 Emails per month are free of charge.',
          },
          {
            header: 'No refresh',
            body: 'Your web page will never reload under any circumstance: page browsing, submitting, adding, deleting & ammending.',
          },
          {
            header: 'Real-time',
            body: "Any data added by customers/users will automatically be viewed on your screen in real-time. You don't need to refresh your web site.",
          },
          {
            header: 'Mobile-ready',
            body: 'All sites are mobile-view-ready.',
          },
        ],
      },
      infos5: [
        {
          title: 'Websites',
          text: '+',
          maxValue: 100,
          incrementBy: 1,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
        {
          title: 'Clients',
          text: '+',
          maxValue: 2000,
          incrementBy: 20,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
        {
          title: 'Prospects',
          text: '+',
          maxValue: 30000,
          incrementBy: 300,
          updateInterval: 10,
          color: 'rgb(112 144 183)',
          strokeWidth: 1.5,
        },
      ],
      infos6: [
        {
          header: 'Create your account',
          body: 'Begin prospecting and gather information.',
          onClick: navigate => window.open('/auth'),
        },
        {
          header: 'Learn about Instagram prospecting',
          body: 'Understand the basics of freelancing with the most powerful social media platform.',
          onClick: navigate => window.open('https://mailchimp.com/resources/instagram-marketing-tips/'),
        },
        {
          header: 'Learn about email marketing',
          body: 'Understand the basics of email marketing.',
          onClick: navigate => window.open('https://blog.hubspot.com/marketing/make-emails-more-clickable-list'),
        },
      ],
      contact: {
        header: 'Contact Us',
        header2: 'Have a query for us?',
        flipperHeader: 'Contact Us',
        flipperHeader2: 'Different ways to reach us',
        flipperImg: '/img/misc/contact.jpg',
      },
    },

    // CART
    cart: {
      cartName: 'Cart',
      cartSubmitButton: 'Contact Us',
      showPrice: true,
      showQuantity: true,
      showTotal: true,
      cartOnSubmit: async ({ e, inputs, cart, pushEmail }) => {
        e.preventDefault();

        try {
          await pushEmail({
            // Mail
            subject: 'New Order',
            to: configObj.email.email1,
            successReply: 'Order submitted to support',
            // HTML
            template: 'adminCart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });

          await pushEmail({
            // Mail
            subject: 'Order submitted',
            to: inputs.email,
            successReply: 'Your order was submitted successfully',
            // HTML
            template: 'cart',
            args: { ...inputs, ...cart, currency: configObj.currency.currencySign },
          });
        } catch (e) {
          console.error(e);
        }
      },
    },

    // REVIEWS
    reviews: [
      {
        title: 'Faha Aesthetics',
        body: "Made my job a lot easier. Don't have to rely on external resources for managing client bookings. Everything under one portal is a dream!",
      },
      {
        title: 'Kochal Barber',
        body: "We've been working with Webify Lake for the past 6 months and have seen a significant increase in our revenue since launching our new website. The team at Webify Lake truly understood our business and created a website that not only looks great, but effectively showcases our services and has made it easier for customers to book appointments online. Their ongoing support and maintenance have also been invaluable. We highly recommend Webify Lake to any barbershop looking to boost their online presence and sales.",
      },
      {
        title: 'NodeConfig',
        body: "Results speak for themselves. Professional web design coupled with no refresh when browising pages isn't something you see often. They managed to build a front-end with a backend and a custom executable wrtitten in Python. What I love the most was their priority on making the apps as secure as possible. No BS, just results.",
      },
    ],

    // ALBUMS
    albums: {
      showAlbum: true,
      showInstagram: true,
      folder1: {
        md: 4,
        album: [
          '/img/album1/1.JPG',
          '/img/album1/2.JPG',
          '/img/album1/3.JPG',
          '/img/album1/4.JPG',
          '/img/album1/5.JPG',
          '/img/album1/6.JPG',
          '/img/album1/7.JPG',
          '/img/album1/8.JPG',
          '/img/album1/9.JPG',
          '/img/album1/10.JPG',
        ],
      },
    },

    // SERVICES
    services: {
      showPrice: true,
      showImage: false,
      multiclick: true,
      style: {},
      className: '',
      serviceGroups: [
        {
          serviceGroupName: 'Websites',
          serviceItems: [
            {
              serviceName: 'Website',
              id: 'Website',
              price: 300,
              minPrice: true,
              imageSrc: '/img/cs.jpg',
              text: 'We can build any custom applications for your business.',
            },
            {
              serviceName: 'Website w/ Backend',
              id: 'Website w/ Backend',
              price: 780,
              minPrice: true,
              imageSrc: '/img/cwb.jpg',
              text: 'We can build any custom applications for your business. This appplication includes a backend that can complete complex operations: user accounts, item storage',
            },
            {
              serviceName: 'Booking Website',
              id: 'Booking Website',
              price: 779.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MPw2gDGy83J8wI2YFW4lfkz'
                  : 'price_1MPxpWDGy83J8wI2iyiPXKPn',
              imageSrc: '/img/sc.jpg',
              text: 'This type of application gives you the flexibility to manage all your customers in one portal. You will be able to run any operations with admin users.',
              resourceType: '1 GiB Memory, 1 vCPU, 1,000 GiB Bandwidth, 25 GiB SSD',
            },
            {
              serviceName: 'Catalogue Site',
              id: 'Catalogue Site',
              price: 300,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MPy6EDGy83J8wI23tmfsGza'
                  : 'price_1MPy4QDGy83J8wI2Yn8kxEzj',
              imageSrc: '/img/ca.jpg',
              text: 'With this app your customers will be presented with a list of your services',
            },
          ],
        },
        {
          serviceGroupName: 'Hosting',
          serviceItems: [
            {
              serviceName: 'Hosting',
              id: 'Hosting',
              price: 779.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'Host your application online. You can choose to link your Git project with your application, enabling you to automatically update your application on Git events.',
            },
            {
              serviceName: 'Static Hosting',
              id: 'Static Hosting',
              price: 9.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MPzz2DGy83J8wI2L8hapm1l'
                  : 'price_1MPzybDGy83J8wI2UfiNT85H',
              imageSrc: '/img/sh.jpg',
              text: 'We can host your static website',
            },
          ],
        },
        {
          serviceGroupName: 'Email Marketing',
          serviceItems: [
            {
              serviceName: 'Email Copywriting',
              id: 'Email Copywriting',
              price: 779.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'Advanced 2 month Email Copywriting',
            },
          ],
        },
        {
          serviceGroupName: 'X Ghost-writing',
          serviceItems: [
            {
              serviceName: 'X Ghost-writing',
              id: 'X Ghost-writing',
              price: 779.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'Advanced 2 month X Ghost-writing',
            },
          ],
        },
        {
          serviceGroupName: 'Account Management',
          serviceItems: [
            {
              serviceName: 'Instagram Management',
              id: 'Instagram Management',
              price: 779.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'Advanced 2 month Instagram Management',
            },
          ],
        },
        {
          serviceGroupName: 'Facebook Ads',
          serviceItems: [
            {
              serviceName: 'Facebook Ads',
              id: 'Facebook Ads',
              price: 99.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'FaceBook centric ads for dropshippers',
            },
            {
              serviceName: 'Instagram Ads',
              id: 'Instagram Ads',
              price: 99.99,
              priceId:
                env.REACT_APP_ENV === 'production'
                  ? 'price_1MQ044DGy83J8wI2Fw1Bu24h'
                  : 'price_1MQ01zDGy83J8wI2k4mNH1CN',
              minPrice: true,
              imageSrc: '/img/db.jpg',
              text: 'Instagram centric ads for dropshippers',
            },
          ],
        },
      ],
    },

    // INPUT OPTIONS
    inputsOptions: {
      sortByOptions: [
        { value: '', label: 'Select' },
        { value: 'createdAt:asc', label: 'Oldest' },
        { value: 'createdAt:desc', label: 'Newest' },
        { value: 'updatedAt:asc', label: 'Updated - oldest' },
        { value: 'updatedAt:desc', label: 'Updated - soonest' },
      ],
    },

    // EXTRAS
    extras: {
      showCartInHeader: false,
      iconColor1: '8aabed',
      iconColor2: '3374f5',
    },
  };

  return configObj;
};
