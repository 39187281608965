///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { CustomInput, CloseButton } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Item = ({ service, putItem, deleteItem }) => {
  // State
  const [quantity, setQuantity] = useState(service.quantity);

  // onChange functions
  const onQuantityChange = e => {
    const q = e.target.value;
    if (q < 0) deleteItem(service.id);
    else putItem({ ...service, quantity: q });
  };

  // Lifecycle hooks
  useEffect(() => {
    setQuantity(service.quantity);
  }, [service.quantity]);

  // JSX
  return (
    <>
      <td>{service.serviceName}</td>
      {c.cart.showPrice && (
        <td>
          {c.currency.currencySign}
          {service.price}
        </td>
      )}
      {c.cart.showQuantity && (
        <td>
          <CustomInput type='number' value={quantity} onChange={onQuantityChange} />
        </td>
      )}
      <td>
        <CloseButton onClick={_ => deleteItem(service.id)} />
      </td>
    </>
  );
};

export default Item;
