///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from '@ibiliaze/react-base';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import { PUSH_EMAIL_SUCCESS, PUSH_EMAIL_FAILURE } from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost, resolveEmail } from '../utils/resolveEnv';
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

export const pushEmailSuccess = () => ({ type: PUSH_EMAIL_SUCCESS });
export const pushEmailFailure = errorMessage => ({ type: PUSH_EMAIL_FAILURE, errorMessage });
export const pushEmail = data => async dispatch => {
  try {
    dispatch(setAlert('Sending email...', false, null, null, true));
    const res = await api.post('/.netlify/functions/sendEmail', {
      ...data,
      emailParams: {
        originUrl: process.env.REACT_APP_API_URL,
        name: process.env.REACT_APP_NAME,
        from: process.env.REACT_APP_NAME,
        ...resolveEmail(),
      },
    });

    dispatch(setAlert(res.data, false, res.status, PUSH_EMAIL_SUCCESS));
    dispatch(pushEmailSuccess());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUSH_EMAIL_FAILURE));
    dispatch(pushEmailFailure(e.message));
  }
};
