///////////////////////////////////////////////////////////////////////////////////MODULES
import { FullPage, Section } from '@ibiliaze/react-base';
//////////////////////////////////////////////////////////////////////////////////////////

const FailedPaymentPage = () => (
  <FullPage className='below-header p-b-m custom-page'>
    <Section>
      <h1 className='t-align-c'>Payment Failed</h1>
    </Section>
  </FullPage>
);

export default FailedPaymentPage;
