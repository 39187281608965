///////////////////////////////////////////////////////////////////////////////////MODULES
import { Link } from 'react-router-dom';
import { FullPage, Section } from '@ibiliaze/react-base';
//////////////////////////////////////////////////////////////////////////////////////////

const NotFoundPage = () => (
  <FullPage className='below-header p-b-m custom-page'>
    <Section>
      <h1 className='t-align-c'>404 Not Found 😔</h1>
      <h1 className='t-align-c'>
        <Link to='/'>Go Home</Link>
      </h1>
    </Section>
  </FullPage>
);

export default NotFoundPage;
